import { above, below } from 'src/components/global/mediaqueries';
import AspectWrapper from 'src/components/AspectWrapper';
import Image from 'src/components/Image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import theme from 'src/styles/theme';

const QuoteContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
`;

const QuoteImage = styled(Image)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transform: scale(1.1);
    overflow: hidden;

    &.standard {
        filter: blur(${theme.blur.standard.desktop});
    }

    ${below.tablet_s} {
        &.standard {
            filter: blur(${theme.blur.standard.mobile});
        }
    }
`;

const QuoteTextContainer = styled('div')`
    text-align: center;
    width: calc(6 * 100%/8);
    position: relative;
    z-index: 2;

    ${above.tablet_s} {
        width: calc(8 * 100%/12);
    }
`;

const Quote = styled('div')`
    display: block;
    font-family: 'Tiempos';
    line-height: 135%;
    font-weight: 400;

    ${theme.fontSize.serif.mobile._24};
    ${theme.fontSize.serif.tablet._24};
    ${theme.fontSize.serif.desktop._48};

    ${theme.spacing.mobile._24('margin-bottom')};
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._32('margin-bottom')};
`;

const Byline = styled(Tag)``;

const QuoteModule = ({data}) => {
    const {
        backgroundImage,
        blurAmount,
        byline,
        quote,
        textColor,
    } = data;

    const imageSize = blurAmount !== 'none' ? 'small' : 'large';
    const images =
        backgroundImage.mobile.image[`${imageSize}`] &&
        backgroundImage.desktop.image[`${imageSize}`]
            ? [
                backgroundImage.mobile.image[`${imageSize}`][0],
                {
                    ...backgroundImage.desktop.image[`${imageSize}`][0],
                    media: `${above.tablet_s.replace('@media ', '')}`,
                },
            ]
            : backgroundImage.desktop.image[`${imageSize}`]
            ? backgroundImage.desktop.image[`${imageSize}`]
            : backgroundImage.mobile.image[`${imageSize}`];

    return (
        <AspectWrapper ratio={[4 / 5, null, 2 / 1]}>
            <QuoteContainer>
                <QuoteImage
                    className={blurAmount}
                    fluid={images}
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                    }}
                />
                <QuoteTextContainer>
                    <Quote style={{color: textColor}}>{quote && quote}</Quote>
                    <Byline style={{color: textColor}}>{byline && byline}</Byline>
                </QuoteTextContainer>
            </QuoteContainer>
        </AspectWrapper>
    );
};

QuoteModule.propTypes = {
    data: PropTypes.shape({
        backgroundImage: PropTypes.object,
        blurAmount: PropTypes.string,
        byline: PropTypes.string,
        quote: PropTypes.string,
        textColor: PropTypes.string,
    }),
};

QuoteModule.defaultProps = {
    data: PropTypes.shape({
        backgroundImage: {},
        blurAmount: 'small',
        byline: '',
        quote: '',
        textColor: 'black',
    }),
};

export default QuoteModule;
